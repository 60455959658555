import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@fluentui/react-components";
import { AddCircle24Filled } from "@fluentui/react-icons";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { IPublicClientApplication } from "@azure/msal-browser";

import { connectAxios } from "../../utils/axios";
import {
  OutlookProjects,
  OutlookSignUpOrSignInResponse,
} from "../../models/v1";
import ProjectGrid from "./ProjectGrid";

import { CompoundButton } from "@fluentui/react-components";
import NewProjectForm from "./NewProjectForm";

/* global console, Office */

interface AppProps {
  msalInstance: IPublicClientApplication;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

enum AppStates {
  SHOW_PROJECT_LIST = "show_project_list",
  SHOW_CREATE_PROJECT = "show_create_project",
}

const App = ({ msalInstance }: AppProps) => {
  const styles = useStyles();
  const [currentState, setCurrentState] = useState<AppStates>(
    AppStates.SHOW_PROJECT_LIST,
  );
  const [authHeader, setAuthHeader] = useState<string>("");
  const [projects, setProjects] = useState<OutlookProjects>({ projects: [] });
  const [isInitialized, setIsInitialized] = useState(false);

  const axios = useMemo(() => connectAxios({ debug: true }), []);

  const fetchAuthToken = async () => {
    try {
      if (!isInitialized) {
        await msalInstance.initialize();
        setIsInitialized(true);
      }
      const response = await msalInstance.acquireTokenSilent({
        scopes: [
          "https://graph.microsoft.com/User.Read",
          "https://graph.microsoft.com/Mail.ReadWrite",
          "https://graph.microsoft.com/MailboxSettings.ReadWrite",
          "offline_access",
        ],
      });
      return response.accessToken;
    } catch (silentError) {
      console.error(
        "Silent token acquisition failed, acquiring via popup",
        silentError,
      );
      try {
        const response = await msalInstance.acquireTokenPopup({
          scopes: [
            "https://graph.microsoft.com/User.Read",
            "https://graph.microsoft.com/Mail.ReadWrite",
            "https://graph.microsoft.com/MailboxSettings.ReadWrite",
            "offline_access",
          ],
        });
        return response.accessToken;
      } catch (popupError) {
        console.error("Popup token acquisition failed", popupError);
        throw popupError;
      }
    }
  };

  useEffect(() => {
    const authenticateAndFetchData = async () => {
      try {
        console.log("Starting MSAL authentication.");
        const accessTokenMsal = await fetchAuthToken();
        console.log(
          "MSAL authentication successful, accessTokenMsal: " + accessTokenMsal,
        );
        if (accessTokenMsal) {
          console.log("Starting Office authentication.");
          const accessToken = await Office.auth.getAccessToken({
            allowSignInPrompt: true,
            allowConsentPrompt: true,
          });
          console.log(
            "Office authentication successful, accessToken: " + accessToken,
          );

          const authHeaderVar = "Bearer " + accessToken;
          const loginResponse = await axios.post(
            "/v1/login/outlook_sign_up_or_sign_in",
            "",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: authHeaderVar,
              },
            },
          );
          const outlookResponse = OutlookSignUpOrSignInResponse.parse(
            loginResponse.data,
          );
          console.log("LOGIN RESPONSE: " + outlookResponse.status);
          setAuthHeader(authHeaderVar);
        }
      } catch (error) {
        console.error(
          "Authentication and data fetch error: " + JSON.stringify(error),
        );
      }
    };

    authenticateAndFetchData();
  }, [axios, msalInstance, isInitialized, setAuthHeader, setProjects]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (authHeader !== "") {
        try {
          const projectsResponse = await axios.get(
            "/v1/microsoft_outlook/project",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: authHeader,
              },
            },
          );
          setProjects(OutlookProjects.parse(projectsResponse.data));
        } catch (error) {
          console.error("/GET projects error: " + error);
        }
      }
    };

    fetchProjects();
  }, [authHeader, currentState, axios]);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>
        <div className={styles.root}>
          {currentState === AppStates.SHOW_CREATE_PROJECT ? (
            <NewProjectForm
              axios={axios}
              authHeader={authHeader}
              onSubmitClick={async () => {
                const projectsResponse = await axios.get(
                  "/v1/microsoft_outlook/project",
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: authHeader,
                    },
                  },
                );
                setProjects(OutlookProjects.parse(projectsResponse.data));
                setCurrentState(AppStates.SHOW_PROJECT_LIST);
              }}
              onCancelClick={async () =>
                setCurrentState(AppStates.SHOW_PROJECT_LIST)
              }
            />
          ) : (
            <>
              <ProjectGrid projects={projects} />
              <CompoundButton
                icon={<AddCircle24Filled />}
                secondaryContent="New project"
                appearance="subtle"
                size="large"
                onClick={() => setCurrentState(AppStates.SHOW_CREATE_PROJECT)}
              />
            </>
          )}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadingComponent />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
