import * as React from "react";
import { AxiosInstance } from "axios";
import { useState } from "react";
import {
  makeStyles,
  useId,
  CompoundButton,
  Input,
  Label,
} from "@fluentui/react-components";
import {
  Checkmark24Regular,
  DismissCircle24Regular,
} from "@fluentui/react-icons";

/* global console */

interface NewProjectFormProps {
  axios: AxiosInstance;
  authHeader: string;
  onSubmitClick: () => {};
  onCancelClick: () => {};
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Prevent the example from taking the full width of the page (optional)
    maxWidth: "400px",
    // Stack the label above the field (with 2px gap per the design system)
    "> div": {
      display: "flex",
      flexDirection: "column",
      marginBottom: "20px",
      marginLeft: "10px",
      marginRight: "10px",
    },
  },
});

const NewProjectForm: React.FC<NewProjectFormProps> = ({
  axios,
  authHeader,
  onSubmitClick,
  onCancelClick,
}) => {
  const [id, setId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");

  const styles = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const projectsResponse = await axios.post(
        "/v1/microsoft_outlook/project",
        JSON.stringify({
          id: id,
          name: name,
          address: address,
          description: description,
          address_components: {
            postal_code: postalCode,
            street_address: address,
          },
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
          },
        },
      );
      console.log("Response: " + projectsResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const projectIdId = useId("content-project-id");
  const projectNameId = useId("content-project-name");
  const projectAddressId = useId("content-project-address");
  const projectDescriptionId = useId("content-project-description");

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.root}>
        <div>
          <Label htmlFor={projectIdId}>Project ID</Label>
          <Input
            type="text"
            name="project_id"
            value={id}
            id={projectIdId}
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </div>

        <div>
          Project name:
          <Input
            type="text"
            name="project_name"
            value={name}
            id={projectNameId}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div>
          Project address:
          <Input
            type="text"
            name="project_address"
            value={address}
            id={projectAddressId}
            onChange={(e) => {
              const matches = e.target.value.match(new RegExp(",?([0-9]{4})"));
              if (matches !== null && matches !== undefined) {
                if (matches.length > 0) {
                  // matches[0] is the whole matched string. Take the first group,
                  // at index 1.
                  setPostalCode(matches[1]);
                }
              }
              setAddress(e.target.value);
            }}
          />
        </div>

        <div>
          Project description:
          <Input
            type="text"
            name="project_description"
            value={description}
            id={projectDescriptionId}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <CompoundButton
          icon=<Checkmark24Regular />
          secondaryContent="Add project"
          appearance="subtle"
          size="large"
          disabled={id === "" || name === "" || address === ""}
          onClick={(e) => {
            handleSubmit(e);
            onSubmitClick();
          }}
        ></CompoundButton>
        <CompoundButton
          icon=<DismissCircle24Regular />
          secondaryContent="Cancel"
          appearance="subtle"
          size="large"
          onClick={onCancelClick}
        ></CompoundButton>
      </div>
    </form>
  );
};

export default NewProjectForm;
